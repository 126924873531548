import React from 'react';
import { Link, graphql } from "gatsby";
import Layout from '../components/layout';
import './pages.css';

function TagsPage(props) {

    const data = props.data.allMarkdownRemark.group;

    return (
        <Layout className="tags">
            
                <h1>All tags</h1>
                {
                    data.map((tags, i) => (
                        <Link to={`/${tags.fieldValue}`} key={i}>
                            {tags.fieldValue} {`(${tags.totalCount})`}
                        </Link>
                    ))
                }
        </Layout>
    )

}

export default TagsPage;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }

`